/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {toAbsoluteUrl, KTSVG} from '../../../_metronic/helpers'

type Props = {
    id: number
    icon: string
    title: string
}

const Card4: FC<Props> = ({id, icon, title}) => {
    return (
        <div className='col-12 col-sm-6 col-xl-2'>
            <div className='card h-100'>
                <div className='card-body d-flex justify-content-center text-center flex-column p-8'>
                    <a href={'categories/' + id} className='text-gray-800 text-hover-primary d-flex flex-column'>
                        <div className='symbol symbol-75px mb-6'>
                            <img src={toAbsoluteUrl(icon)} alt={title} />
                        </div>
                        <div className='fs-5 fw-bolder mb-2'>{title}</div>
                    </a>
                </div>
            </div>
        </div>
    )
}

export {Card4}
