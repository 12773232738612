import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { getUserByToken, login, register, requestSetNewPassword } from '../core/_requests';
import clsx from 'clsx';
import * as Yup from "yup";
import { useAuth } from '../core/Auth'
import { useFormik } from 'formik';
import { useAuthStateManage } from '../../../../useContext/ContextProvider';
const initialValues = {
   
    password: "",
    changepassword: "",
   
  };
  
  const registrationSchema = Yup.object().shape({
     password: Yup.string()
    .min(8, "Must be 8 characters or more")
    .matches(/[a-z]+/, "One lowercase character")
    // .matches(/[A-Z]+/, "One uppercase character")
    .matches(/[@$!%*#?&()^.,`]+/, "One special character")
    .matches(/\d+/, "One number")
      .required("Password is required"),
    changepassword: Yup.string()
      .required("Password confirmation is required")
      .when("password", {
        is: (val: string) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Password and Confirm Password didn't match"
        ),
      }),
  });
  
function SetNewPassowrd() {
    const [loading, setLoading] = useState(false);
    const { authEmail, getAuthEmail} = useAuthStateManage();
  const { saveAuth, setCurrentUser } = useAuth();
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        const {data: auth} = await requestSetNewPassword(
          values.password,authEmail.email
        );
        saveAuth(auth);
        const { data: user } = await getUserByToken(auth.api_token);
        setLoading(false)
        const dataObj ={
          email: '',
          password: ''
        }
        getAuthEmail(dataObj);
        localStorage.removeItem('checkEmail')
        setCurrentUser(user);

      } catch (error) {
        console.log(error);
        saveAuth(undefined);
        const axiosError = error as AxiosError; 
      if (axiosError.response) {
        setStatus(axiosError?.response?.data?.message);
      } else {
        setStatus('An unexpected error occurred');
      }
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  return (
    <form
      className="form w-100 "
      // className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      // id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
        <h1 className="display-3 text-center text-Blue">BUY & SELL</h1>
            <h1 className="fs-1 text-black text-center mt-3 mb-5">Set New Password</h1>
           
      <div className="mb-5 fv-row" data-kt-password-meter="true">
        <div className="mb-1">
          <label className="form-label fw-bold text-dark fs-6">
            Creat Your New Password
          </label>
          <div className="position-relative mb-3">
            <input
              type="password"
              placeholder="Password"
              autoComplete="off"
              {...formik.getFieldProps("password")}
              className={clsx(
                "form-control form-control-lg ",
                {
                  "is-invalid":
                    formik.touched.password && formik.errors.password,
                },
                {
                  "is-valid":
                    formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.password}</span>
                </div>
              </div>
            )}
            <p className="text-muted fs-6 mt-1">
              Use 8 or more characters with a mix of letters, numbers & symbols.
            </p>
          </div>
        </div>
      </div>
      <div className="fv-row mb-10">
        {/* <label className="form-label fw-bolder text-dark fs-6">
          Confirm Password
        </label> */}
        <input
          type="password"
          placeholder="Confirm Password"
          autoComplete="off"
          {...formik.getFieldProps("changepassword")}
          className={clsx(
            "form-control form-control-lg ",
            {
              "is-invalid":
                formik.touched.changepassword && formik.errors.changepassword,
            },
            {
              "is-valid":
                formik.touched.changepassword && !formik.errors.changepassword,
            }
          )}
        />
        {formik.touched.changepassword && formik.errors.changepassword && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.changepassword}</span>
            </div>
          </div>
        )}
      </div>

      <div className="d-flex gap-3">
      <div className='w-50'>
        <Link to='/auth/login'>
            <button
                type='button'
                id='kt_login_signup_form_cancel_button'
                className='btn btn-lg btn-light-primary w-100 mb-5'
            >
              Cancel
            </button>
          </Link> 
          </div>
        <div className='w-50'>
        <button
          type="submit"
          id="kt_sign_up_submit"
          className="py-3 text-white fs-4 rounded bg-Blue w-100 mb-4"
          disabled={
            formik.isSubmitting || !formik.isValid 
          }
        >
          {!loading && <span className="indicator-label">Crete Account</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...{" "}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
        </div>
        
      </div>
      {/* end::Form group */}
    </form>
  )
}

export default SetNewPassowrd