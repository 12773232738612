/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {Location} from "./Location";
import {ServiceWrapper} from "./ServiceWrapper";
import {ConnectionWrapper} from "./ConnectionWrapper";
import {ProductWrapper} from "./ProductWrapper";

const HomeWrapperPage: FC = () => {

    return (
        <>
            {/* begin::Row */}
            {/* <div className='row gy-5 g-xl-8'> */}
            <div className=''>
                
                <div className='col-xxl-12'>
                    <p className='text-black'>Products / Services near me</p>
                    <Location className={'mb-xl-2'} />
                </div>
                {/* <div className='col-xxl-12'>
                    <ServiceWrapper className='card-xl-stretch mb-xl-8' />
                </div>
                <div className='col-xxl-12'>
                    <ProductWrapper />
                </div>
                <div className='col-xxl-12'>
                    <ConnectionWrapper />
                </div> */}
            </div>
        </>
    )
}

const HomeWrapper: FC = () => {
    const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.HOME'})}</PageTitle>
            <HomeWrapperPage />
        </>
    )
}

export {HomeWrapper}