import axios from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8888/api/v1';


export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/verify_token`
export const LOGIN_URL = `${API_URL}/auth/login`
export const REGISTER_URL = `${API_URL}/auth/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot_password`
export const REQUEST_ENTER_OTP = `${API_URL}/auth/enter_otp`
export const REQUEST_SET_NEW_PASSWORD  = `${API_URL}/auth/set-new-password`
export const REQUEST_REGISTERD_SIGNUP  = `${API_URL}/auth/registered-signUp`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return AuthModel
export  function  register(
  email: string,
  userName: string,
  password: string,
  describeYou: string
) {
 const res =  axios.post(REGISTER_URL, {
    email,
    userName,
    password,
    describeYou
  })
  return  res
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function requestSetNewPassword(password: string,email:string) {
  return axios.post(REQUEST_SET_NEW_PASSWORD, {
    password,email
  })
}
export function requestRegisterdSignUp(email:string,password: string) {
  return axios.post(REQUEST_REGISTERD_SIGNUP, {
    password,email
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}

export function sendOTPEmail(otp:number,email: string){
  return axios.post(REQUEST_ENTER_OTP, {
    otp,email
  })
}