import clsx from "clsx";
import { useFormik } from "formik";
import { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import { useAuthStateManage } from "../../../../useContext/ContextProvider";
import { getUserByToken, login,  requestRegisterdSignUp,  sendOTPEmail } from "../core/_requests";
import { useAuth } from "../core/Auth";
import { AxiosError } from "axios";

const Otp = () => {
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const { saveAuth, setCurrentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [errorStatus, setErrorStatus] = useState('');
  const { authEmail,getAuthEmail,  getAuthSignupKey,authSignUpKey } = useAuthStateManage();
  const [KeytoSetNewPassword, setKeytoSetNewPassword] =useState<boolean>(false)
    const handleSubmitOTP = async (event: any) => {
    event.preventDefault();
    setLoading(true)
    try {
      if(authSignUpKey){
      const res = await sendOTPEmail(Number(otp), authEmail?.email);
      if(res?.data?.data?.success === false) setErrorStatus(res?.data?.data?.message)
      if(res?.data?.data?.success && authSignUpKey) {
        const {data: auth} = await requestRegisterdSignUp(authEmail?.email, authEmail?.password)
        console.log(auth);
        setLoading(false)
        saveAuth(auth);
        const { data: user } = await getUserByToken(auth?.api_token);
        const dataObj ={
          email: '',
          password: ''
        }
        getAuthEmail(dataObj);
        getAuthSignupKey(false);
        localStorage.removeItem('checkEmail')
        localStorage.removeItem('signupKey')
        setCurrentUser(user);
      }
    } else {
      const res = await sendOTPEmail(Number(otp), authEmail?.email);
      if(res?.data?.data?.success === false) setErrorStatus(res?.data?.data?.message)
      if(res?.data?.data?.success === true){
        setKeytoSetNewPassword(true)
        navigate("/auth/set-newpassword");
      }
    }
    }catch (error){
      console.log(error); 
      const axiosError = error as AxiosError; 
      if (axiosError.response) {
        setErrorStatus(axiosError.response.data.message);
      } else {
        setErrorStatus('An unexpected error occurred');
      }
      saveAuth(undefined); 
      setLoading(false);
    }
  };
  
 
  
  useEffect(()=>{
    if(authSignUpKey || authEmail.email){
      navigate('/auth/enter-otp') 
    }
  },[])
  
  useEffect(()=>{
  if(KeytoSetNewPassword){
  navigate('/auth/set-newpassword')
  }
  },[KeytoSetNewPassword])
  
  return (
    <>
      <form className="form w-100 " onSubmit={handleSubmitOTP}>
        <h1 className="display-3 text-center text-Blue">BUY & SELL</h1>
        <h1 className="fs-1 text-black text-center my-3">Enter OTP</h1>
        {errorStatus && (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{errorStatus}</div>
        </div>
      )}
        <p className="text-black fs-4 text-center">
          Enter your code we have sent to your{" "}
          <a
            href="mailto:johndoe@gmail.com"
            className="link-primary fs-6 fw-bolder"
          >
            {authEmail.email}
          </a>
        </p>
        <div className="w-100">
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={5}
            inputType="number"
            shouldAutoFocus
            containerStyle={{ width: "100%", display: "flex", gap: 3 }}
            inputStyle="py-4 px-3 w-25 pr-3 rounded border outline-none"
            renderSeparator={<span> </span>}
            renderInput={(props) => <input {...props} />}
          />
        </div>

        <div className="d-flex gap-3 mt-5">
          <div className="w-50">
            <Link to="/auth/login">
              <button
                type="button"
                id="kt_login_signup_form_cancel_button"
                className="btn btn-lg btn-light-primary w-100 mb-5"
              >
                Cancel
              </button>
            </Link>
          </div>
          <div className="w-50">
            <button
              type="submit"
              id="kt_sign_up_submit"
              className="py-3 text-white fs-4 rounded bg-Blue w-100 mb-4"
              // disabled={!loading}
            >
              {!loading && <span className="indicator-label">Submit</span>}
           {loading && (
             <span className="indicator-progress" style={{ display: "block" }}>
               Please wait...{" "}
               <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
             </span>
           )} 
            </button>
          </div>
        </div>
      </form>{" "}
    </>
  );
};

export default Otp;
