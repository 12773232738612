import React, {useState} from 'react'
import {GoogleMap, Marker, useJsApiLoader} from '@react-google-maps/api'

const containerStyle = {
    // width: '400px',
    width: '100%',
    height: '400px',
}

const center = {
    lat: 41.44446438845721,
    lng: -100.96027904886358
}

interface LocationPickerProps {
    onLocationChange: (_: google.maps.LatLngLiteral | null) => void
}

function LocationPicker({onLocationChange = () => {}}: LocationPickerProps) {
    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyDpI8I9i5cmCD7C2BcC6HcCLSbyeoO6WvY',
    })

    const [map, setMap] = React.useState(null)

    const [marker, setMarker] = useState<google.maps.LatLngLiteral | null>(null)

    const onLoad = React.useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds()
        map.fitBounds(bounds)
        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={6}
            // onLoad={onLoad}
            onUnmount={onUnmount}
            onClick={(e: google.maps.MapMouseEvent) => {
                if (e.latLng) {
                    onLocationChange(e.latLng.toJSON())
                    setMarker(e.latLng.toJSON())
                }
            }}
        >
            {marker && (
                <Marker
                    key={`marker-pickup-location`}
                    position={marker}
                    //   onLoad={marker => markerLoadHandler(marker, place)}
                    //   onClick={event => markerClickHandler(event, place)}
                    icon={{
                        path:
                            "M12.75 0l-2.25 2.25 2.25 2.25-5.25 6h-5.25l4.125 4.125-6.375 8.452v0.923h0.923l8.452-6.375 4.125 4.125v-5.25l6-5.25 2.25 2.25 2.25-2.25-11.25-11.25zM10.5 12.75l-1.5-1.5 5.25-5.25 1.5 1.5-5.25 5.25z",
                        fillColor: "#0000ff",
                        fillOpacity: 1.0,
                        strokeWeight: 0,
                        scale: 1.25
                    }}
                />
            )}

            {/* Child components, such as markers, info windows, etc. */}
            <></>
        </GoogleMap>
    ) : (
        <></>
    )
}

export default React.memo(LocationPicker)
