import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {TransactionWrapper} from '../pages/dashboard/TransactionWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {HomeWrapper} from "../pages/home/HomeWrapper";
import {FreelanceWrapper} from "../pages/freelance/FreelanceWrapper";
import {ServiceWrapper} from "../pages/service/ServiceWrapper";
import {ProductWrapper} from "../pages/product/ProductWrapper";
import {CategoryWrapper} from "../pages/category/CategoryWrapper";
import {CategoryDetailWrapper} from "../pages/category/CategoryDetailWrapper";
import {AdWrapper} from "../pages/ad/AdWrapper";
import {AccountTypeWrapper} from "../pages/profile/AccountTypeWrapper";

const PrivateRoutes = () => {
  const AdminPageWrapper = lazy(() => import('../pages/admin/AdminPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='home' element={<HomeWrapper />} />
        <Route path='auth/*' element={<Navigate to='/home' />} />
        {/* Pages */}
        <Route path='pages/admin' element={<AdminPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route path='pages/categories' element={<CategoryWrapper />} />
        <Route path='pages/categories/:id' element={<CategoryDetailWrapper />} />
        <Route path='pages/freelance' element={<FreelanceWrapper />} />
        <Route path='pages/services' element={<ServiceWrapper />} />
        <Route path='pages/products' element={<ProductWrapper />} />
        <Route path='pages/ads' element={<AdWrapper />} />
        <Route path='pages/transaction' element={<TransactionWrapper />} />
        <Route path='account-type' element={<AccountTypeWrapper />} />
        <Route
          path='pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
