/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {toAbsoluteUrl, KTSVG} from '../../../_metronic/helpers'

type Props = {
    image?: string
    name: string
    price: string
}

// Product Card
const Card2: FC<Props> = ({
                              image = '',
                              name,
                              price,
                          }) => {
    return (
        <div className='col-md-4 col-xxl-3'>
            <div className="card card-flush h-xl-100">
                <div className="card-body text-center pb-5">
                    <div
                        className='bgi-no-repeat bgi-size-cover rounded min-h-250px mb-5'
                        style={{
                            backgroundImage: `url('${toAbsoluteUrl(image)}')`,
                        }}
                    ></div>
                    <div className="d-flex align-items-end flex-stack mb-1">
                        <div className="text-start">
                            <span className="fw-bolder text-gray-800 cursor-pointer text-hover-primary fs-4 d-block">{name}</span>
                        </div>
                        <span className="text-gray-600 text-end fw-bolder fs-6">${price}</span>
                    </div>
                </div>
                <div className="card-footer d-flex flex-stack pt-0">
                    <a className="btn btn-sm btn-primary flex-shrink-0 me-2" data-bs-target="#kt_modal_bidding"
                       data-bs-toggle="modal">Add to Cart</a>
                    <a className="btn btn-sm btn-light flex-shrink-0"
                       href="#">View Item</a>
                </div>
            </div>
        </div>
    )
}

export {Card2}
