import React from 'react'
import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {MegaMenu} from './MegaMenu'
import {useIntl} from 'react-intl'

export function MenuInner() {
  const intl = useIntl()
  return (
    <>
    
      <MenuItem title={intl.formatMessage({id: 'MENU.HOME'})} to='/home' />
      <MenuItem title='Categories' to='/pages/categories' />
      <MenuItem title='Freelance' to='/pages/freelance' />
      <MenuItem title='Services' to='/pages/services' />
      <MenuItem title='Products' to='/pages/products' />
      <MenuItem title='ADs' to='/pages/ads' />
      <MenuItem title='Transaction' to='/pages/transaction' />
      <MenuItem title='Admin' to='/pages/admin' />
      
    </>
  )
}
