/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import {Card3} from "../../components/Card3";

type Props = {
    className: string
}

const ServiceWrapper: React.FC<Props> = ({className}) => {

    return (
        <>
            <div className='d-flex flex-wrap flex-stack mb-6'>
                <h3 className='fw-bolder my-2'>
                    Services
                    <span className='fs-6 text-gray-400 fw-bold ms-1'>(100)</span>
                </h3>
            </div>

            <div className='row g-6 g-xl-9'>
                <div className='col-md-6 col-xxl-4'>
                    <Card3
                        avatar='/media/avatars/300-1.jpg'
                        image='/uploads/services/1.jpg'
                        category='Mango, Java, Python'
                        description='Outlines keep you honest. They stop you from indulging in poorly thought-out metaphors
                        about driving and keep you focused on the overall structure of your post'
                        review={55}
                        vote={98}
                    />
                </div>
                <div className='col-md-6 col-xxl-4'>
                    <Card3
                        avatar='/media/avatars/300-2.jpg'
                        image='/uploads/services/2.jpg'
                        category='Mango, Java, Python'
                        description='Outlines keep you honest. They stop you from indulging in poorly thought-out metaphors
                        about driving and keep you focused on the overall structure of your post'
                        review={55}
                        vote={98}
                    />
                </div>
                <div className='col-md-6 col-xxl-4'>
                    <Card3
                        avatar='/media/avatars/300-4.jpg'
                        image='/uploads/services/3.jpg'
                        category='Mango, Java, Python'
                        description='Outlines keep you honest. They stop you from indulging in poorly thought-out metaphors
                        about driving and keep you focused on the overall structure of your post'
                        review={55}
                        vote={98}
                    />
                </div>
                <div className='col-md-6 col-xxl-4'>
                    <Card3
                        avatar='/media/avatars/300-5.jpg'
                        image='/uploads/services/4.jpg'
                        category='Mango, Java, Python'
                        description='Outlines keep you honest. They stop you from indulging in poorly thought-out metaphors
                        about driving and keep you focused on the overall structure of your post'
                        review={55}
                        vote={98}
                    />
                </div>
                <div className='col-md-6 col-xxl-4'>
                    <Card3
                        avatar='/media/avatars/300-6.jpg'
                        image='/uploads/services/5.jpg'
                        category='Mango, Java, Python'
                        description='Outlines keep you honest. They stop you from indulging in poorly thought-out metaphors
                        about driving and keep you focused on the overall structure of your post'
                        review={55}
                        vote={98}
                    />
                </div>
                <div className='col-md-6 col-xxl-4'>
                    <Card3
                        avatar='/media/avatars/300-7.jpg'
                        image='/uploads/services/6.jpg'
                        category='Mango, Java, Python'
                        description='Outlines keep you honest. They stop you from indulging in poorly thought-out metaphors
                        about driving and keep you focused on the overall structure of your post'
                        review={55}
                        vote={98}
                    />
                </div>
            </div>
        </>
    )
}

export {ServiceWrapper}
