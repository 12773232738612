import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {Card1} from "../../components/Card1";

const FreelanceWrapperPage: FC = () => {
    return (
        <>
            <div className='row gy-5 g-xl-8'>
                <div className='col-xxl-12'>
                    <div className='d-flex flex-wrap flex-stack mb-6'>
                        <h3 className='fw-bolder my-2 text-secondary'>
                            All
                            <span className='fs-6 text-gray-400 fw-bold ms-1'>(59)</span>
                        </h3>
                    </div>

                    <div className='row g-6 g-xl-9'>
                        <div className='col-md-6 col-xxl-4'>
                            <Card1
                                avatar='/media/avatars/300-6.jpg'
                                name='Emma Smith'
                                job='Art Director'
                                avgEarnings='$14,560'
                                totalEarnings='$236,400'
                                online={true}
                            />
                        </div>
                        <div className='col-md-6 col-xxl-4'>
                            <Card1
                                avatar='/media/avatars/300-1.jpg'
                                name='Max Smith'
                                job='Software Enginer'
                                avgEarnings='$14,560'
                                totalEarnings='$236,400'
                                online={true}
                            />
                        </div>
                        <div className='col-md-6 col-xxl-4'>
                            <Card1
                                avatar='/media/avatars/300-11.jpg'
                                name='Max Smith'
                                job='Software Enginer'
                                avgEarnings='$14,560'
                                totalEarnings='$236,400'
                            />
                        </div>
                        <div className='col-md-6 col-xxl-4'>
                            <Card1
                                avatar='/media/avatars/300-9.jpg'
                                name='Max Smith'
                                job='Software Enginer'
                                avgEarnings='$14,560'
                                totalEarnings='$236,400'
                            />
                        </div>
                        <div className='col-md-6 col-xxl-4'>
                            <Card1
                                avatar='/media/avatars/300-10.jpg'
                                name='Max Smith'
                                job='Software Enginer'
                                avgEarnings='$14,560'
                                totalEarnings='$236,400'
                            />
                        </div>
                        <div className='col-md-6 col-xxl-4'>
                            <Card1
                                color='danger'
                                name='Melody Macy'
                                job='Marketing Analytic'
                                avgEarnings='$14,560'
                                totalEarnings='$236,400'
                                online={true}
                            />
                        </div>
                        <div className='col-md-6 col-xxl-4'>
                            <Card1
                                avatar='/media/avatars/300-15.jpg'
                                name='Max Smith'
                                job='Software Enginer'
                                avgEarnings='$14,560'
                                totalEarnings='$236,400'
                            />
                        </div>
                        <div className='col-md-6 col-xxl-4'>
                            <Card1
                                avatar='/media/avatars/300-16.jpg'
                                name='Max Smith'
                                job='Software Enginer'
                                avgEarnings='$14,560'
                                totalEarnings='$236,400'
                                online={true}
                            />
                        </div>
                        <div className='col-md-6 col-xxl-4'>
                            <Card1
                                avatar='/media/avatars/300-14.jpg'
                                name='Melody Macy'
                                job='Marketing Analytic'
                                avgEarnings='$14,560'
                                totalEarnings='$236,400'
                                online={true}
                            />
                        </div>
                    </div>

                    <div className='d-flex flex-stack flex-wrap pt-10'>
                        <div className='fs-6 fw-bold text-gray-700'>Showing 1 to 10 of 50 entries</div>

                        <ul className='pagination'>
                            <li className='page-item previous'>
                                <a href='#' className='page-link'>
                                    <i className='previous'></i>
                                </a>
                            </li>

                            <li className='page-item active'>
                                <a href='#' className='page-link'>
                                    1
                                </a>
                            </li>

                            <li className='page-item'>
                                <a href='#' className='page-link'>
                                    2
                                </a>
                            </li>

                            <li className='page-item'>
                                <a href='#' className='page-link'>
                                    3
                                </a>
                            </li>

                            <li className='page-item'>
                                <a href='#' className='page-link'>
                                    4
                                </a>
                            </li>

                            <li className='page-item'>
                                <a href='#' className='page-link'>
                                    5
                                </a>
                            </li>

                            <li className='page-item'>
                                <a href='#' className='page-link'>
                                    6
                                </a>
                            </li>

                            <li className='page-item next'>
                                <a href='#' className='page-link'>
                                    <i className='next'></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

const FreelanceWrapper: FC = () => {
    const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.FREELANCE'})}</PageTitle>
            <FreelanceWrapperPage />
        </>
    )
}

export {FreelanceWrapper}