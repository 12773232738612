import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'

const AccountType: FC = () => {
    return (
        <>

        </>
    )
}

const AccountTypeWrapper: FC = () => {
    const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.ADS'})}</PageTitle>
            <AccountType />
        </>
    )
}

export {AccountTypeWrapper}