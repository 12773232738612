/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {toAbsoluteUrl, KTSVG} from '../../../_metronic/helpers'

type Props = {
    image?: string
    title: string
    user: string
}

// AD Card
// icon fonticon-play doesn't work
const Card5: FC<Props> = ({image = '', title, user}) => {
    return (
        <div className="col-sm-3 mb-3 mb-sm-0">
            <div className="card card-flush h-xl-100">
                <div className="card-body text-center pb-5">
                    <div className="card-rounded position-relative mb-5">
                        <div
                            className='bgi-no-repeat bgi-size-cover rounded min-h-250px mb-5'
                            style={{
                                backgroundImage: `url('${toAbsoluteUrl(image)}')`,
                            }}
                        ></div>
                        <button className="btn btn-icon h-auto w-auto p-0 ms-4 mb-4 position-absolute bottom-0 right-0"
                                data-kt-element="list-play-button">
                            <i className="fonticon-play text-white fs-2x" data-kt-element="list-play-icon"></i>
                            <i className="fonticon-pause text-white fs-2x d-none" data-kt-element="list-pause-icon"></i>
                        </button>
                    </div>
                    <div className="m-0">
                        <a href="#" className="text-gray-800 text-hover-primary fs-3 fw-bolder d-block mb-2">{title}</a>
                        <span className="fw-bolder fs-6 text-gray-400 d-block lh-1">{user}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export {Card5}
