import React, {FC, useEffect} from 'react'
import {MenuInner} from './MenuInner'
import {SwapperComponent} from '../../../assets/ts/components'

const Header: FC = () => {
  useEffect(() => {
    SwapperComponent.reinitialization()
  }, [])

  return (
    <div
      className='header-menu align-items-start bg-black'
      data-kt-drawer='true'
      data-kt-drawer-name='header-menu'
      data-kt-drawer-activate='{default: true, lg: true}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_header_menu_mobile_toggle'
      data-kt-swapper='true'
      data-kt-swapper-mode='prepend'
      data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}"
    >
      <div className='flex-column align-items-center w-100'>
      <div>
      <h1 className='display-6 font-bold text-center text-primary'>BUY & SELL</h1>
          
          </div>
      <div
        className='menu menu-lg-rounded menu-column menu-start menu-state-bg 
        menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 
        fw-bold my-5  align-items-stretch'
        id='#kt_header_menu'
        data-kt-menu='true'
      > 
        
        <MenuInner />
      </div>
      </div>
    </div>
  )
}

export {Header}
