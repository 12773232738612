import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {Card4} from "../../components/Card4";

const categories = [
    { name: "Business and Legal", image: "handshake.png", description: "description" },
    { name: "Artists", image: "paint-brush.png", description: "description" },
    { name: "Catering & food", image: "hot.png", description: "description" },
    { name: "Event organizers", image: "staff.png", description: "description" },
    { name: "Travel & tours", image: "travel.png", description: "description" },
    { name: "Tutoring and home schooling", image: "homeschooling.png", description: "description" },
    { name: "Doctors, therapists and nurse", image: "stethoscope.png", description: "description" },
    { name: "Laboratory and diagnostic services", image: "flask.png", description: "description" },
    { name: "Pharmacy and medical products", image: "blood-sample.png", description: "description" },
    { name: "Engineers & technicians", image: "settings.png", description: "description" },
    { name: "I.T Services", image: "computer.png", description: "description" },
    { name: "Real estate & Construction and contractors", image: "realestate.png", description: "description" },
    { name: "Agriculture products and Services", image: "handshake.png", description: "description" },
    { name: "Personal care & grooming", image: "personalcare.png", description: "description" },
    { name: "Clothing & Designers", image: "fashion.png", description: "description" },
    { name: "Shoes", image: "shoe.png", description: "description" },
    { name: "Multimedia Services", image: "digitalization.png", description: "description" },
    { name: "Transportation and shipping", image: "shipped.png", description: "description" },
    { name: "Auto services", image: "car-service.png", description: "description" },
    { name: "Lost & Found", image: "found.png", description: "description" }
];

const CategoryWrapperPage: FC = () => {
    return (
        <>
            <div className='row gy-5 g-xl-8'>
                    {
                        categories.map((category, index) => {

                            return (
                                    <Card4 icon={`/uploads/icons/${category.image}`} title={category.name} id={index} />
                                )
                        })
                    }
            </div>
        </>
    )
}

const CategoryWrapper: FC = () => {
    const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.CATEGORIES'})}</PageTitle>
            <CategoryWrapperPage />
        </>
    )
}

export {CategoryWrapper}