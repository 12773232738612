/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { getUserByToken, login } from "../core/_requests";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { useAuth } from "../core/Auth";
import { useAuthStateManage } from "../../../../useContext/ContextProvider";

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
  password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
});

const initialValues = {
  email: "farhanaliabbasi891@gmail.com",
  password: "Zetsol@1234",
};

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false);
  const { saveAuth, setCurrentUser } = useAuth();
  const {
    authEmail,
    getAuthEmail,
    increment,
    getAuthSignupKey,
    authSignUpKey,
  } = useAuthStateManage();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        const { data: auth } = await login(values.email, values.password);
        console.log(auth);
        saveAuth(auth);
        const { data: user } = await getUserByToken(auth.api_token);
        setCurrentUser(user);
      } catch (error) {
        console.error(error);
        saveAuth(undefined);
        setStatus("The login detail is incorrect");
        setSubmitting(false);
        setLoading(false);
      }
    },
  });
  useEffect(() => {
    if (authEmail.email) {
      navigate("/auth/enter-otp");
      // navigate('/auth/set-newpassword')
    }
  }, [authEmail.email]);
  return (
    <form
      className="form  w-100"
      onSubmit={formik.handleSubmit}
      noValidate
      // id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      {/* <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Sign In</h1>
        <div className='text-gray-400 fw-bold fs-4'>
          New Here?{' '}
          <Link to='/auth/registration' className='link-primary fw-bolder'>
            Create an Account
          </Link>
        </div>
      </div> */}
      {/* begin::Heading */}

      <h1 className="display-3 text-center text-Blue">BUY & SELL</h1>
      <h1 className="fs-1 text-black text-center my-3">Sign in</h1>
      {formik.status && (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{formik.status}</div>
        </div>
      )}
      {/* begin::Form group */}
      <div className="fv-row mb-10">
        {/* <label className='form-label fs-6 fw-bolder text-dark'>Email</label> */}
        <input
          placeholder="Email"
          {...formik.getFieldProps("email")}
          className={clsx(
            "form-control form-control-lg ",
            { "is-invalid": formik.touched.email && formik.errors.email },
            {
              "is-valid": formik.touched.email && !formik.errors.email,
            }
          )}
          type="email"
          name="email"
          autoComplete="off"
        />
        {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container">
            <span role="alert">{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="fv-row mb-10">
        <div className="d-flex justify-content-between mt-n5">
          <div className="d-flex flex-stack mb-2">
            {/* begin::Label */}
            {/* <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label> */}
            {/* end::Label */}
            {/* begin::Link */}

            {/* end::Link */}
          </div>
        </div>
        <input
          type="password"
          autoComplete="off"
          {...formik.getFieldProps("password")}
          className={clsx(
            "form-control form-control-lg ",
            {
              "is-invalid": formik.touched.password && formik.errors.password,
            },
            {
              "is-valid": formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      <div className="d-flex justify-content-end mb-5 ">
        <Link
          onClick={() => increment(2)}
          to="/auth/forgot-password"
          className="link-primary fs-6 fw-bolder"
          // style={{marginLeft: '5px'}}
        >
          Forgot Password ?
        </Link>
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className="text-center">
        <button
          type="submit"
          id="kt_sign_in_submit"
          className="btn btn-lg btn-primary w-100 mb-5"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className="indicator-label">Sign in</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
      <p className="text-black fs-4 text-center">
        Not a member yet?{" "}
        <Link
          onClick={() => {
            let val = [1, 2, 1];
            const rand = Math.floor(Math.random() * val.length);
            console.log(rand);
            increment(rand);
          }}
          to="/auth/registration"
          className="link-primary fs-6 fw-bolder"
          // style={{marginLeft: '5px'}}
        >
          Sign up
        </Link>{" "}
      </p>
      {/* end::Action */}
    </form>
  );
}
