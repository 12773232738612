import React, { createContext, useContext, useState, ReactNode } from 'react';

// Define the context type
interface AuthEmailPass{
  email: string
  password: string
}
interface GlobalContextType {
  count: number;
  increment: (val: number) => void;
  authEmail: AuthEmailPass
  getAuthEmail: (authEmail:AuthEmailPass)=>void
  authSignUpKey: boolean 
  getAuthSignupKey: (authSignUpKey: boolean)=>void
}

// Create the CounterContext
const GlobalStateContext = createContext<GlobalContextType | undefined>(undefined);

// Create a provider component
export const GlobalStateProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const img = [9,8,4]
  let persitsAuthEmail: AuthEmailPass;
  let persitsAuthSigupKey: boolean;
  try {
    const storedEmail = localStorage.getItem('checkEmail');
    const storedSignUpKey = localStorage.getItem('signupKey');
    persitsAuthEmail = storedEmail ? JSON.parse(storedEmail) : { email: '', password: '' }; 
    persitsAuthSigupKey = storedSignUpKey ? JSON.parse(storedSignUpKey) : false; 
    console.log(persitsAuthSigupKey,'persitsAuthSigupKey')
  } catch (error) {
    console.error('Error parsing authEmail from localStorage:', error);
    persitsAuthEmail = {email: '', password: ''};  
    persitsAuthSigupKey =false;
  }
  const [count, setCount] = useState<number>(Math.floor(Math.random() * img.length));
  const [authEmail, setAuthEmail] =useState<AuthEmailPass>(persitsAuthEmail)
  const [authSignUpKey, setAuthSignUpKey] =useState<boolean>(persitsAuthSigupKey)
  const increment = (val:number) => {
    setCount(val);
  };
  const getAuthEmail=(authEmail:AuthEmailPass)=>{
    setAuthEmail(authEmail)
  }
  const getAuthSignupKey=(authSignUpKey:boolean)=>{
    console.log(authSignUpKey)
    setAuthSignUpKey(authSignUpKey)
  }
 
   return (
    <GlobalStateContext.Provider value={{ count, increment, getAuthEmail, authEmail,authSignUpKey , getAuthSignupKey }}>
      {children}
    </GlobalStateContext.Provider>
  );
};
// Create a custom hook to use the GlobalStateContext
export const useAuthStateManage = (): GlobalContextType => {
  const context = useContext(GlobalStateContext);
  if (!context) {
    throw new Error("useCounter must be used within a CounterProvider");
  }
  return context;
};
