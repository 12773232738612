/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import LocationPicker from "../../components/LocationPicker/index";

type LocationProps = {
    className: string
}
const Location: React.FC<LocationProps> = ({className}) => {
    const [location, setLocation] = useState<google.maps.LatLngLiteral | null>();
    console.log(location);

    return (
        <div className={`card bg-primary ${className}`}>
            <div className='card-body p-1'>
                <LocationPicker onLocationChange={setLocation} />
            </div>
        </div>
    )
}

export {Location}
