import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {ServiceWrapper} from "../home/ServiceWrapper";
import {ProductWrapper} from "../home/ProductWrapper";

const CategoryDetailWrapperPage: FC = () => {
    return (
        <>
            <div className='row gy-5 g-xl-8'>
                <div className='col-xxl-12'>
                    <ServiceWrapper className='card-xl-stretch mb-xl-8' />
                </div>
                <div className='col-xxl-12'>
                    <ProductWrapper />
                </div>
            </div>
        </>
    )
}

const CategoryDetailWrapper: FC = () => {
    return (
        <>
            <PageTitle breadcrumbs={[]}>Business and Legal</PageTitle>
            <CategoryDetailWrapperPage />
        </>
    )
}

export {CategoryDetailWrapper}