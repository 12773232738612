/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import {Card2} from "../../components/Card2";

export function ProductWrapper() {

    return (
        <>
            <div className='d-flex flex-wrap flex-stack mb-6'>
                <h3 className='fw-bolder my-2'>
                    Products
                    <span className='fs-6 text-gray-400 fw-bold ms-1'>(509)</span>
                </h3>
            </div>

            <div className='row g-6 g-xl-9'>
                <Card2
                    image='/uploads/products/3.jpg'
                    name='Clean Tools Set 1'
                    price='330'
                />
                <Card2
                    image='/uploads/products/9.jpg'
                    name='Clean Tools Set 1'
                    price='330'
                />
                <Card2
                    image='/uploads/products/10.jpg'
                    name='Clean Tools Set 2'
                    price='1,230'
                />
                <Card2
                    image='/uploads/products/12.jpg'
                    name='Clean Tools Set 3'
                    price='850'
                />
                <Card2
                    image='/uploads/products/11.jpg'
                    name='Clean Tools Set 4'
                    price='2,330'
                />
                <Card2
                    image='/uploads/products/4.jpg'
                    name='Clean Tools Set 4'
                    price='2,330'
                />
                <Card2
                    image='/uploads/products/7.jpg'
                    name='Clean Tools Set 3'
                    price='850'
                />
                <Card2
                    image='/uploads/products/8.jpg'
                    name='Clean Tools Set 4'
                    price='2,330'
                />
            </div>
        </>
    )
}
