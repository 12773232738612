import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword} from '../core/_requests'
import { useAuthStateManage} from '../../../../useContext/ContextProvider'
import { AxiosError } from 'axios'

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function ForgotPassword() {
  const navigate  =useNavigate()
  const { authEmail,getAuthEmail, increment, getAuthSignupKey,authSignUpKey } = useAuthStateManage();
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)

  

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      try{
        const res = await requestPassword(values.email);
        const emails =res?.data?.data?.message.split(' ')[1];
        setLoading(false)
        const dataObj ={
          email: emails,
          password: ''
        }
        getAuthEmail(dataObj)
        getAuthSignupKey(false)
        localStorage.setItem('checkEmail', JSON.stringify(dataObj))
        navigate('/auth/enter-otp')
      }catch(error){
        const axiosError = error as AxiosError; 
      if (axiosError.response) {
        setStatus(axiosError?.response?.data?.message);
        setSubmitting(false)
        setLoading(false)
      } else {
        setStatus('An unexpected error occurred');
      }
      }
    },
  })
useEffect(()=>{
if(authEmail?.email){
  navigate('/auth/enter-otp')
}
},[])
  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className="display-3 text-center text-primary">BUY & SELL</h1>
        <h1 className="fs-1 text-black text-center my-3">Forgot Password ?</h1>
        </div>
        {formik.status && (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{formik.status}</div>
        </div>
      )}
        {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>Sent password reset. Please check your email</div>
          </div>
        )}
        <div className='fv-row mb-10'>
          <label className='text-gray-400  fs-5 mb-3'>Enter your email to reset your password.</label>
          <input
            type='email'
            placeholder='Email'
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex gap-7 mx-auto'>
          <div className='w-100'>
          <Link to='/auth/login'>
            <button
              type='button'
              // id='kt_login_password_reset_form_cancel_button'
              className='btn w-100  border fw-bold'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              Cancel
            </button>
          </Link>{' '}
          </div>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn w-100 btn-primary fw-bold'
            disabled={
              formik.isSubmitting ||  !formik.values.email}
          >
            {!loading && <span className="indicator-label">Submit</span>}
            {loading && (
              <span className='indicator-progress' style={{ display: "block" }}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        <p className="text-black fs-4 mt-5 text-center">
        Already have an account? {" "}
          <Link
            onClick={()=>{
              let val = [2,0,1]
              const rand = Math.floor(Math.random() * val.length);
              increment(rand)}}
            to="/auth/login"
            className="link-primary fs-6 fw-bolder"
            // style={{marginLeft: '5px'}}
          >
            Sign in
          </Link>{" "}
        </p>
        {/* end::Form group */}
      </form>
    </>
  )
}
