import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {Card5} from "../../components/Card5";

const AdWrapperPage: FC = () => {
    return (
        <>
            <div className='row gy-5 g-xl-8'>
                <div className='col-xxl-12'>
                    <div className='d-flex flex-wrap flex-stack mb-6'>
                        <h3 className='fw-bolder my-2 text-secondary'>
                            All
                            <span className='fs-6 text-gray-400 fw-bold ms-1'>(59)</span>
                        </h3>
                    </div>

                    <div className='row g-6 g-xl-9'>
                        <Card5
                            image='/media/stock/600x600/img-61.jpg'
                            title='Daily Podcast'
                            user='Darlene Robertson'
                        />
                        <Card5
                            image='/media/stock/600x600/img-60.jpg'
                            title='Daily Podcast'
                            user='Darlene Robertson'
                        />
                        <Card5
                            image='/media/stock/600x600/img-63.jpg'
                            title='Daily Podcast'
                            user='Darlene Robertson'
                        />
                        <Card5
                            image='/media/stock/600x600/img-56.jpg'
                            title='Daily Podcast'
                            user='Darlene Robertson'
                        />
                        <Card5
                            image='/media/stock/600x600/img-32.jpg'
                            title='Daily Podcast'
                            user='Darlene Robertson'
                        />
                        <Card5
                            image='/media/stock/600x600/img-58.jpg'
                            title='Daily Podcast'
                            user='Darlene Robertson'
                        />
                        <Card5
                            image='/media/stock/600x600/img-55.jpg'
                            title='Daily Podcast'
                            user='Darlene Robertson'
                        />
                        <Card5
                            image='/media/stock/600x600/img-2.jpg'
                            title='Daily Podcast'
                            user='Darlene Robertson'
                        />
                    </div>

                    <div className='d-flex flex-stack flex-wrap pt-10'>
                        <div className='fs-6 fw-bold text-gray-700'>Showing 1 to 10 of 50 entries</div>

                        <ul className='pagination'>
                            <li className='page-item previous'>
                                <a href='#' className='page-link'>
                                    <i className='previous'></i>
                                </a>
                            </li>

                            <li className='page-item active'>
                                <a href='#' className='page-link'>
                                    1
                                </a>
                            </li>

                            <li className='page-item'>
                                <a href='#' className='page-link'>
                                    2
                                </a>
                            </li>

                            <li className='page-item'>
                                <a href='#' className='page-link'>
                                    3
                                </a>
                            </li>

                            <li className='page-item'>
                                <a href='#' className='page-link'>
                                    4
                                </a>
                            </li>

                            <li className='page-item'>
                                <a href='#' className='page-link'>
                                    5
                                </a>
                            </li>

                            <li className='page-item'>
                                <a href='#' className='page-link'>
                                    6
                                </a>
                            </li>

                            <li className='page-item next'>
                                <a href='#' className='page-link'>
                                    <i className='next'></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

const AdWrapper: FC = () => {
    const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.ADS'})}</PageTitle>
            <AdWrapperPage />
        </>
    )
}

export {AdWrapper}