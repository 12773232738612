/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {toAbsoluteUrl, KTSVG} from '../../../_metronic/helpers'

type Props = {
    image?: string
    title: string
    description: string
    price: string
}

// Product Card
const Card6: FC<Props> = ({
                              image = '',
                              title,
                              description,
                              price,
                          }) => {
    return (

        //         <div
        //             className='bgi-no-repeat bgi-size-cover rounded min-h-250px mb-5'
        //             style={{
        //                 backgroundImage: `url('${toAbsoluteUrl(image)}')`,
        //             }}
        //         ></div>
        //         <div className="d-flex align-items-end flex-stack mb-1">
        //             <div className="text-start">
        //                 <span className="fw-bolder text-gray-800 cursor-pointer text-hover-primary fs-4 d-block">{name}</span>
        //             </div>
        //             <span className="text-gray-600 text-end fw-bolder fs-6">${price}</span>
        //         </div>
        //     </div>
        //     <div className="card-footer d-flex flex-stack pt-0">
        //         <a className="btn btn-sm btn-primary flex-shrink-0 me-2" data-bs-target="#kt_modal_bidding"
        //            data-bs-toggle="modal">Add to Cart</a>
        //         <a className="btn btn-sm btn-light flex-shrink-0"
        //            href="#">View Item</a>
        //     </div>
        // </div>
        <div className="col-md-4">
            <div className="card card-flush h-xl-100">
                <div className="card-body pb-5">
                    <div className="card-xl-stretch">
                        <a className="d-block overlay" data-fslightbox="lightbox-hot-sales"
                           href="assets/media/stock/600x400/img-23.jpg">
                            <div
                                className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-175px"
                                style={{
                                    backgroundImage: `url('${toAbsoluteUrl(image)}')`,
                                }}></div>
                            <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                <i className="bi bi-eye-fill fs-2x text-white"></i>
                            </div>
                        </a>
                        <div className="mt-5">
                            <a href="#" className="fs-4 text-dark fw-bolder text-hover-primary text-dark lh-base">{title}</a>
                            <div className="fw-bold fs-5 text-gray-600 text-dark mt-3">
                                {description}
                            </div>
                            <div className="fs-6 fw-bolder mt-5 d-flex flex-stack">
                                <span className="badge border border-dashed fs-2 fw-bolder text-dark p-2">
                                    <span className="fs-6 fw-bold text-gray-400">$</span>{price}</span>
                                <a href="#" className="btn btn-sm btn-primary">Purchase</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export {Card6}
