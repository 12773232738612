/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { Registration } from "./components/Registration";
import { ForgotPassword } from "./components/ForgotPassword";
import { Login } from "./components/Login";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { Image } from "react-bootstrap";
import buysellLogin from "../../../assests/buysellLogin.png";
import { buysellForgot, buysellSignup } from "../../indexImages";
import { useAuthStateManage} from "../../../useContext/ContextProvider";
import Otp from "./components/Otp";
import SetNewPassowrd from "./components/SetNewPassowrd";

const AuthLayout = () => {
  const {count} =useAuthStateManage()
  useEffect(() => {
    document.body.classList.add("bg-white");
    return () => {
      document.body.classList.remove("bg-white");
    };
    
  }, []);
  
  
  return (
    <>
      <div className="d-flex loginCustom h-100">
        <div className="w-100 w-md-50   d-flex justify-content-center align-items-center">
          <div
            className="d-flex justify-content-center align-items-center"
            // style={{
            //   backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/2.png')})`,
            // }}
          >
            {/* begin::Content */}
            <div className="d-flex  p-10 pb-lg-0">
              {/* begin::Logo */}
              <a href="#" className="mb-0">
                {/*<img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-1.svg')} className='h-45px' />*/}
              </a>
              {/* end::Logo */}
              {/* begin::Wrapper */}
              <div className="w-lg-500px mx-auto">
                <Outlet />
              </div>
              {/* end::Wrapper */}
            </div>
            {/* end::Content */}
            {/* begin::Footer */}
            {/* <div className='d-flex flex-center flex-column-auto p-10'>
        <div className='d-flex align-items-center fw-bold fs-6'>
          <a href='#' className='text-muted text-hover-primary px-2'>
            About
          </a>

          <a href='#' className='text-muted text-hover-primary px-2'>
            Contact
          </a>

          <a href='#' className='text-muted text-hover-primary px-2'>
            Contact Us
          </a>
        </div>
      </div> */}
            {/* end::Footer */}
          </div>
        </div>
        <div
          className="w-0 w-md-50 d-none d-md-block d-md-flex loginRightBox  flex-column justify-content-center align-items-center"
          style={{ height: "100%" }}
        >
          <div className="">
            <Image src={authScreen[count]} alt="" fluid />
          </div>
        </div>
      </div>
    </>
  );
};
const authScreen = [buysellLogin, buysellSignup, buysellForgot];
const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path="login" element={<Login />} />
      <Route path="registration" element={<Registration />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="enter-otp" element={<Otp />} />
      <Route path="set-newpassword" element={<SetNewPassowrd />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
);

export { AuthPage };
