import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {Card3} from "../../components/Card3";

const ServiceWrapperPage: FC = () => {
    return (
        <>
            <div className='row gy-5 g-xl-8'>
                <div className='col-xxl-12'>
                    <div className='d-flex flex-wrap flex-stack mb-6'>
                        <h3 className='fw-bolder my-2 text-secondary'>
                            All
                            <span className='fs-6 text-gray-400 fw-bold ms-1'>(59)</span>
                        </h3>
                    </div>

                    <div className='row g-6 g-xl-9'>
                        <div className='col-md-6 col-xxl-4'>
                            <Card3
                                avatar='/media/avatars/300-1.jpg'
                                image='/uploads/services/1.jpg'
                                category='Mango, Java, Python'
                                description='Outlines keep you honest. They stop you from indulging in poorly thought-out metaphors
                        about driving and keep you focused on the overall structure of your post'
                                review={55}
                                vote={98}
                            />
                        </div>
                        <div className='col-md-6 col-xxl-4'>
                            <Card3
                                avatar='/media/avatars/300-2.jpg'
                                image='/uploads/services/2.jpg'
                                category='Mango, Java, Python'
                                description='Outlines keep you honest. They stop you from indulging in poorly thought-out metaphors
                        about driving and keep you focused on the overall structure of your post'
                                review={55}
                                vote={98}
                            />
                        </div>
                        <div className='col-md-6 col-xxl-4'>
                            <Card3
                                avatar='/media/avatars/300-4.jpg'
                                image='/uploads/services/3.jpg'
                                category='Mango, Java, Python'
                                description='Outlines keep you honest. They stop you from indulging in poorly thought-out metaphors
                        about driving and keep you focused on the overall structure of your post'
                                review={55}
                                vote={98}
                            />
                        </div>
                        <div className='col-md-6 col-xxl-4'>
                            <Card3
                                avatar='/media/avatars/300-5.jpg'
                                image='/uploads/services/4.jpg'
                                category='Mango, Java, Python'
                                description='Outlines keep you honest. They stop you from indulging in poorly thought-out metaphors
                        about driving and keep you focused on the overall structure of your post'
                                review={55}
                                vote={98}
                            />
                        </div>
                        <div className='col-md-6 col-xxl-4'>
                            <Card3
                                avatar='/media/avatars/300-6.jpg'
                                image='/uploads/services/5.jpg'
                                category='Mango, Java, Python'
                                description='Outlines keep you honest. They stop you from indulging in poorly thought-out metaphors
                        about driving and keep you focused on the overall structure of your post'
                                review={55}
                                vote={98}
                            />
                        </div>
                        <div className='col-md-6 col-xxl-4'>
                            <Card3
                                avatar='/media/avatars/300-7.jpg'
                                image='/uploads/services/6.jpg'
                                category='Mango, Java, Python'
                                description='Outlines keep you honest. They stop you from indulging in poorly thought-out metaphors
                        about driving and keep you focused on the overall structure of your post'
                                review={55}
                                vote={98}
                            />
                        </div>
                        <div className='col-md-6 col-xxl-4'>
                            <Card3
                                avatar='/media/avatars/300-8.jpg'
                                image='/uploads/services/7.jpg'
                                category='Mango, Java, Python'
                                description='Outlines keep you honest. They stop you from indulging in poorly thought-out metaphors
                        about driving and keep you focused on the overall structure of your post'
                                review={55}
                                vote={98}
                            />
                        </div>
                        <div className='col-md-6 col-xxl-4'>
                            <Card3
                                avatar='/media/avatars/300-9.jpg'
                                image='/uploads/services/8.jpg'
                                category='Mango, Java, Python'
                                description='Outlines keep you honest. They stop you from indulging in poorly thought-out metaphors
                        about driving and keep you focused on the overall structure of your post'
                                review={55}
                                vote={98}
                            />
                        </div>
                        <div className='col-md-6 col-xxl-4'>
                            <Card3
                                avatar='/media/avatars/300-11.jpg'
                                image='/uploads/services/9.jpg'
                                category='Mango, Java, Python'
                                description='Outlines keep you honest. They stop you from indulging in poorly thought-out metaphors
                        about driving and keep you focused on the overall structure of your post'
                                review={55}
                                vote={98}
                            />
                        </div>
                    </div>

                    <div className='d-flex flex-stack flex-wrap pt-10'>
                        <div className='fs-6 fw-bold text-gray-700'>Showing 1 to 10 of 50 entries</div>

                        <ul className='pagination'>
                            <li className='page-item previous'>
                                <a href='#' className='page-link'>
                                    <i className='previous'></i>
                                </a>
                            </li>

                            <li className='page-item active'>
                                <a href='#' className='page-link'>
                                    1
                                </a>
                            </li>

                            <li className='page-item'>
                                <a href='#' className='page-link'>
                                    2
                                </a>
                            </li>

                            <li className='page-item'>
                                <a href='#' className='page-link'>
                                    3
                                </a>
                            </li>

                            <li className='page-item'>
                                <a href='#' className='page-link'>
                                    4
                                </a>
                            </li>

                            <li className='page-item'>
                                <a href='#' className='page-link'>
                                    5
                                </a>
                            </li>

                            <li className='page-item'>
                                <a href='#' className='page-link'>
                                    6
                                </a>
                            </li>

                            <li className='page-item next'>
                                <a href='#' className='page-link'>
                                    <i className='next'></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

const ServiceWrapper: FC = () => {
    const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.SERVICES'})}</PageTitle>
            <ServiceWrapperPage />
        </>
    )
}

export {ServiceWrapper}