import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {Card2} from "../../components/Card2";
import {Card6} from "../../components/Card6";

const ProductWrapperPage: FC = () => {
    return (
        <>
            <div className='row gy-5 g-xl-8'>
                <div className='col-xxl-12'>
                    <div className='d-flex flex-wrap flex-stack mb-6'>
                        <h3 className='fw-bolder my-2 text-secondary'>
                            All
                            <span className='fs-6 text-gray-400 fw-bold ms-1'>(59)</span>
                        </h3>
                    </div>

                    <div className='row g-10 mb-10'>
                        <Card6
                            image='/media/stock/600x400/img-23.jpg'
                            title='25 Products Mega Bundle with 50% off discount amazing'
                            description='We’ve been focused on making a the from also not been eye'
                            price='28'
                        />
                        <Card6
                            image='/media/stock/600x400/img-14.jpg'
                            title='25 Products Mega Bundle with 50% off discount amazing'
                            description='We’ve been focused on making a the from also not been eye'
                            price='35'
                        />
                        <Card6
                            image='/media/stock/600x400/img-71.jpg'
                            title='25 Products Mega Bundle with 50% off discount amazing'
                            description='We’ve been focused on making a the from also not been eye'
                            price='50'
                        />
                    </div>

                    <div className='row g-6 g-xl-9 mb-10'>
                        <Card2
                            image='/uploads/products/9.jpg'
                            name='Clean Tools Set 1'
                            price='330'
                        />
                        <Card2
                            image='/uploads/products/10.jpg'
                            name='Clean Tools Set 2'
                            price='1,230'
                        />
                        <Card2
                            image='/uploads/products/12.jpg'
                            name='Clean Tools Set 3'
                            price='850'
                        />
                        <Card2
                            image='/uploads/products/11.jpg'
                            name='Clean Tools Set 4'
                            price='2,330'
                        />
                        <Card2
                            image='/uploads/products/3.jpg'
                            name='Clean Tools Set 1'
                            price='330'
                        />
                        <Card2
                            image='/uploads/products/1.jpg'
                            name='Clean Tools Set 2'
                            price='1,230'
                        />
                        <Card2
                            image='/uploads/products/2.jpg'
                            name='Clean Tools Set 3'
                            price='850'
                        />
                        <Card2
                            image='/uploads/products/4.jpg'
                            name='Clean Tools Set 4'
                            price='2,330'
                        />
                        <Card2
                            image='/uploads/products/5.jpg'
                            name='Clean Tools Set 1'
                            price='330'
                        />
                        <Card2
                            image='/uploads/products/6.jpg'
                            name='Clean Tools Set 2'
                            price='1,230'
                        />
                        <Card2
                            image='/uploads/products/7.jpg'
                            name='Clean Tools Set 3'
                            price='850'
                        />
                        <Card2
                            image='/uploads/products/8.jpg'
                            name='Clean Tools Set 4'
                            price='2,330'
                        />
                    </div>

                    <div className='d-flex flex-stack flex-wrap pt-10'>
                        <div className='fs-6 fw-bold text-gray-700'>Showing 1 to 10 of 50 entries</div>

                        <ul className='pagination'>
                            <li className='page-item previous'>
                                <a href='#' className='page-link'>
                                    <i className='previous'></i>
                                </a>
                            </li>

                            <li className='page-item active'>
                                <a href='#' className='page-link'>
                                    1
                                </a>
                            </li>

                            <li className='page-item'>
                                <a href='#' className='page-link'>
                                    2
                                </a>
                            </li>

                            <li className='page-item'>
                                <a href='#' className='page-link'>
                                    3
                                </a>
                            </li>

                            <li className='page-item'>
                                <a href='#' className='page-link'>
                                    4
                                </a>
                            </li>

                            <li className='page-item'>
                                <a href='#' className='page-link'>
                                    5
                                </a>
                            </li>

                            <li className='page-item'>
                                <a href='#' className='page-link'>
                                    6
                                </a>
                            </li>

                            <li className='page-item next'>
                                <a href='#' className='page-link'>
                                    <i className='next'></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

const ProductWrapper: FC = () => {
    const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.PRODUCTS'})}</PageTitle>
            <ProductWrapperPage />
        </>
    )
}

export {ProductWrapper}