/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { getUserByToken, register } from "../core/_requests";
import { useAuth } from "../core/Auth";
import { Link, useNavigate } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { PasswordMeterComponent } from "../../../../_metronic/assets/ts/components";
import { useAuthStateManage } from "../../../../useContext/ContextProvider";
import { AxiosError } from "axios";

const initialValues = {
  userName: "",
  email: "",
  password: "",
  changepassword: "",
  acceptTerms: false,
  describeYou: "Buyer",
};

const registrationSchema = Yup.object().shape({
  userName: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("User name is required"),
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
  password: Yup.string()
  .min(8, "Must be 8 characters or more")
  .matches(/[a-z]+/, "One lowercase character")
  .matches(/[@$!%*#?&()^.,`]+/, "One special character")
  .matches(/\d+/, "One number")
    .required("Password is required"),
  changepassword: Yup.string()
    .required("Password confirmation is required")
    .when("password", {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Password and Confirm Password didn't match"
      ),
    }),
  acceptTerms: Yup.bool().required("You must accept the terms and conditions"),
  describeYou: Yup.string().required("You must choose Buyer or Seller"),
});

export function Registration() {
  const navigate =useNavigate();
  const { authEmail,getAuthEmail, increment, getAuthSignupKey,authSignUpKey } = useAuthStateManage();
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        const { data } = await register(
          values.email,
          values.userName,
          values.password,
          values.describeYou
        );
        if(data){
          const dataObj ={
            email: data?.data?.email,
            password: data?.data?.password
          }
          getAuthEmail(dataObj);
          getAuthSignupKey(true);
           navigate('/auth/enter-otp');
        }
      } catch (error) {
        console.log(error);
        const axiosError = error as AxiosError; 
      if (axiosError.response) {
        setStatus(axiosError.response.data.message);
      } else {
        setStatus('An unexpected error occurred');
      }
        setSubmitting(false);
        setLoading(false);
      }
    },
  });
  console.log(authEmail,authSignUpKey,'---')

  useEffect(()=>{
  localStorage.setItem('checkEmail',JSON.stringify(authEmail))
  localStorage.setItem('signupKey',JSON.stringify(authSignUpKey))
  }, [authEmail, authSignUpKey])
  useEffect(() => {
    PasswordMeterComponent.bootstrap();
  }, []);
  useEffect(()=>{
  console.log(authEmail.email)
  if(authEmail.email){
  navigate('/auth/enter-otp');
  }
  },[])
  return (
    <form
      className="form w-100 "
      // className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      // id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      <div className=" text-center">
        <h1 className="display-3 text-center text-Blue">BUY & SELL</h1>
        <h1 className="fs-1 text-black text-center my-3">Sign up</h1>
        {/* <div className='text-gray-400 fw-bold fs-4'>
            Already have an account?
            <Link to='/auth/login' className='link-primary fw-bolder' style={{marginLeft: '5px'}}>
              Forgot Password ?
            </Link>
          </div> */}
        {/* end::Link */}
      </div>
      {/* end::Heading */}

      
      {formik.status && typeof formik.status === 'string' && (
    <div className="mb-lg-15 alert alert-danger">
        <div className="alert-text font-weight-bold">{formik.status}</div>
    </div>
)}


      {/* begin::Form group Firstname */}
      <div className="row fv-row mb-7">
        <div className="col-xl-12">
          {/* <label className='form-label fw-bolder text-dark fs-6'>First name</label> */}
          <input
            placeholder="User name"
            type="text"
            autoComplete="off"
            {...formik.getFieldProps("userName")}
            className={clsx(
              "form-control form-control-lg ",
              {
                "is-invalid":
                  formik.touched.userName && formik.errors.userName,
              },
              {
                "is-valid":
                  formik.touched.userName && !formik.errors.userName,
              }
            )}
          />
          {formik.touched.userName && formik.errors.userName && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.userName}</span>
              </div>
            </div>
          )}
        </div>
        <div className="col-xl-12 mt-7">
          {/* begin::Form group Lastname */}
          {/* <div className="fv-row mb-5">
             <label className='form-label fw-bolder text-dark fs-6'>Last name</label> 
            <input
              placeholder="Last name"
              type="text"
              autoComplete="off"
              {...formik.getFieldProps("lastname")}
              className={clsx(
                "form-control form-control-lg form-control-solid",
                {
                  "is-invalid":
                    formik.touched.lastname && formik.errors.lastname,
                },
                {
                  "is-valid":
                    formik.touched.lastname && !formik.errors.lastname,
                }
              )}
            />
            {formik.touched.lastname && formik.errors.lastname && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.lastname}</span>
                </div>
              </div>
            )}
          </div> */}
           <div className="fv-row mb-5">
        {/* <label className="form-label fw-bolder text-dark fs-6">Email</label> */}
        <input
          placeholder="Email"
          type="email"
          autoComplete="off"
          {...formik.getFieldProps("email")}
          className={clsx(
            "form-control form-control-lg ",
            { "is-invalid": formik.touched.email && formik.errors.email },
            {
              "is-valid": formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
          <div>
            <p className="fs-4 text-black  fw-bold">What Describe you?</p>

            <input
              type="radio"
              {...formik.getFieldProps("describeYou")}
              id="Buyers"
              value="Buyer"
              checked={formik.values.describeYou === "Buyer"}
            />
            <label htmlFor="Buyers" className="cursor-pointer fs-4 text-black ps-3 me-6">
              Buyer
            </label>

            <input
              type="radio"
              {...formik.getFieldProps("describeYou")}
              id="Sellers"
              value="Seller"
              checked={formik.values.describeYou === "Seller"}
            />
            <label htmlFor="Sellers" className="cursor-pointer fs-4 text-black ps-3">
              Seller
            </label>

            {formik.touched.describeYou && formik.errors.describeYou && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.describeYou}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Email */}
     
      {/* end::Form group */}

      {/* begin::Form group Password .form-control-solid*/}
      <div className="mb-5 fv-row" data-kt-password-meter="true">
        <div className="mb-1">
          <label className="form-label fw-bolder text-dark fs-6">
            Password
          </label>
          <div className="position-relative mb-3">
            <input
              type="password"
              placeholder="Password"
              autoComplete="off"
              {...formik.getFieldProps("password")}
              className={clsx(
                "form-control form-control-lg ",
                {
                  "is-invalid":
                    formik.touched.password && formik.errors.password,
                },
                {
                  "is-valid":
                    formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.password}</span>
                </div>
              </div>
            )}
            <p className="text-muted fs-6 mt-1">
              Use 8 or more characters with a mix of letters, numbers & symbols.
            </p>
          </div>
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className="fv-row mb-10">
        <label className="form-label fw-bolder text-dark fs-6">
          Confirm Password
        </label>
        <input
          type="password"
          placeholder="Repeat Password"
          autoComplete="off"
          {...formik.getFieldProps("changepassword")}
          className={clsx(
            "form-control form-control-lg ",
            {
              "is-invalid":
                formik.touched.changepassword && formik.errors.changepassword,
            },
            {
              "is-valid":
                formik.touched.changepassword && !formik.errors.changepassword,
            }
          )}
        />
        {formik.touched.changepassword && formik.errors.changepassword && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.changepassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="fv-row mb-10">
        <div className="form-check form-check-custom form-check-solid">
          <input
            className="form-check-input"
            type="checkbox"
            id="kt_login_toc_agree"
            {...formik.getFieldProps("acceptTerms")}
          />
          <label
            className="form-check-label fw-bold text-gray-700 fs-6"
            htmlFor="kt_login_toc_agree"
          >
            I Accept the{" "}
            <Link to="/auth/terms" className="ms-1 link-primary">
            terms & Conditions
            </Link>
            .
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.acceptTerms}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="text-center">
        <button
          type="submit"
          id="kt_sign_up_submit"
          className="py-3 text-white fs-4 rounded bg-Blue w-100 mb-4"
          disabled={
            formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms
          }
        >
          {!loading && <span className="indicator-label">Crete Account</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...{" "}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
        {/* <Link to='/auth/login'>
            <button
                type='button'
                id='kt_login_signup_form_cancel_button'
                className='btn btn-lg btn-light-primary w-100 mb-5'
            >
              Cancel
            </button>
          </Link> */}
        <p className="text-black fs-4 text-center">
        Already have an account? {" "}
          <Link
           onClick={()=>{
            let val = [0,1,2]
            const rand = Math.floor(Math.random() * val.length);
            increment(rand)}}
            to="/auth/login"
            className="text-Blue fs-6 fw-bolder"
            // style={{marginLeft: '5px'}}
          >
            Sign in
          </Link>{" "}
        </p>
      </div>
      {/* end::Form group */}
    </form>
  );
}
